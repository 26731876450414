import type { FunctionComponent } from 'react'
import type { GraduateListResult } from '../data/GraduateListFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import styles from './Graduates.module.sass'

export type GraduatesProps = {
	title?: string
	text?: string
	graduates: GraduateListResult
}

export const Graduates: FunctionComponent<GraduatesProps> = ({ title, text, graduates }) => {
	return (
		<div className={styles.wrapper}>
			<Container size="normal">
				<h3 className={styles.title}>{title}</h3>
				<h3 className={styles.text}>{text}</h3>
			</Container>
			<Container size="fullWidth">
				<div className={styles.graduates}>
					{graduates.items.map((graduate) => (
						<div key={graduate.id} className={styles.graduate}>
							<div className={styles.graduateIn}>
								{graduate.image && (
									<div className={styles.image}>
										<ContemberImage image={graduate.image} />
									</div>
								)}
								<div className={styles.graduateInfo}>
									<div className={styles.graduateName}>{graduate.name}</div>
									<div className={styles.graduateText}>{graduate.text}</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</Container>
		</div>
	)
}
