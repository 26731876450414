// Reexport all svg icons
export { default as arrow } from './arrow.svg'
export { default as arrowLeft } from './arrowLeft.svg'
export { default as arrowLink } from './arrowLink.svg'
export { default as arrowRight } from './arrowRight.svg'
export { default as close } from './close.svg'
export { default as download } from './download.svg'
export { default as file } from './file.svg'
export { default as globe } from './globe.svg'
export { default as hamburger } from './hamburger.svg'
export { default as instagram } from './instagram.svg'
export { default as languageGlobe } from './languageGlobe.svg'
export { default as linkedin } from './linkedin.svg'
export { default as logoMobile } from './logoMobile.svg'
export { default as play } from './play.svg'
export { default as twitter } from './twitter.svg'
