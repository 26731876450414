import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/ImageFragment'
import type { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import styles from './Cover.module.sass'

export type CoverProps = {
	title?: string
	link?: LinkResult
	image?: ImageResult
}

export const Cover: FunctionComponent<CoverProps> = ({ title, link, image }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.column}>
				<div className={styles.title}>{title}</div>
				{link && (
					<div className={styles.linkDesktop}>
						<Button type="link" {...contemberLinkToHrefTargetRel(link)} variant="primary" size="medium">
							{link.title ?? contemberLinkToHref(link)}
						</Button>
					</div>
				)}
			</div>

			{image && (
				<div className={styles.image}>
					<ContemberImage image={image} />
				</div>
			)}
			{link && (
				<div className={styles.linkMobile}>
					<Button type="link" {...contemberLinkToHrefTargetRel(link)} variant="primary" size="medium">
						{link.title ?? contemberLinkToHref(link)}
					</Button>
				</div>
			)}
		</div>
	)
}
