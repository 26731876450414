import clsx from 'clsx'
import 'hiding-header/dist/style.css'
import type { FunctionComponent } from 'react'
import i18n from '../../../i18n'
import { filterNonEmpty } from '../utilities/filterNonEmpty'
import { Icon } from './Icon'
import styles from './LanguageSwitcher.module.sass'

export type LanguageSwitcherProps = {
	localeCode: string
	translations: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
	isPurple?: boolean
}

export const LanguageSwitcher: FunctionComponent<LanguageSwitcherProps> = (props: LanguageSwitcherProps) => {
	const translations = props.translations.filter((tr) => tr.locale?.code !== props.localeCode)

	const existingLocales = props.translations.map((tr) => tr.locale?.code).filter(filterNonEmpty)

	const missingLocales = i18n.locales.filter(
		(locale) => !existingLocales.includes(locale) && locale !== props.localeCode,
	)

	return (
		<div className={clsx(styles.languageSwitcher, props.isPurple && styles.isPurple)}>
			{translations.map((item) => (
				<a
					key={item.id}
					href={item.link?.url}
					onClick={() => {
						document.cookie = `NEXT_LOCALE=${item.locale?.code}; path=/`
					}}
					className={styles.language}
				>
					<span className={styles.languageIcon}>
						<Icon name="languageGlobe" />
					</span>
					<span className={styles.languageLabel}>{item.locale?.code}</span>
				</a>
			))}
			{missingLocales.map((locale, i) => (
				<a
					key={i}
					className={styles.language}
					onClick={() => {
						document.cookie = `NEXT_LOCALE=${locale}; path=/`
					}}
					href={locale === i18n.defaultLocale ? '/' : `/${locale}`}
				>
					<span className={styles.languageIcon}>
						<Icon name="languageGlobe" />
					</span>
					<span className={styles.languageLabel}>{locale}</span>
				</a>
			))}
		</div>
	)
}
