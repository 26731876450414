import type { FunctionComponent } from 'react'
import type { LecturerResult } from '../data/LecturerFragment'
import type { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import { LecturerTile } from './LecturerTile'
import styles from './Lecturers.module.sass'

export type LecturersProps = {
	title?: string
	lecturers: LecturerResult[]
	link?: LinkResult
}

export const Lecturers: FunctionComponent<LecturersProps> = ({ title, lecturers, link }) => {
	return (
		<div className={styles.wrapper}>
			<Container size="fullWidth" disableGutters>
				<h2>{title}</h2>
				<div className={styles.lecturersMobile}>
					{lecturers.map((lecturer) => (
						<div key={lecturer.id} className={styles.lecturer}>
							<LecturerTile key={lecturer.id} lecturer={lecturer} />
						</div>
					))}
				</div>
				<div className={styles.lecturers}>
					{lecturers.map((lecturer) => (
						<LecturerTile key={lecturer.id} lecturer={lecturer} />
					))}
				</div>
				{link && (
					<div className={styles.link}>
						<Button type="link" {...contemberLinkToHrefTargetRel(link)} variant="primary" size="medium">
							{link.title ?? contemberLinkToHref(link)}
						</Button>
					</div>
				)}
			</Container>
		</div>
	)
}
