import type { FunctionComponent } from 'react'
import type { ImageListResult } from '../data/ImageListFragment'
import { ContemberImage } from './ContemberImage'
import styles from './Partners.module.sass'

export type PartnersProps = {
	title?: string
	partners: ImageListResult
}

export const Partners: FunctionComponent<PartnersProps> = ({ title, partners }) => {
	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>{title}</h3>
			<div className={styles.partners}>
				{partners.items.map((partner) => (
					<div key={partner.id} className={styles.partner}>
						{partner.image && (
							<div className={styles.image}>
								<ContemberImage image={partner.image} />
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}
