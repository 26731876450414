import clsx from 'clsx'
import { HidingHeader } from 'hiding-header-react'
import 'hiding-header/dist/style.css'
import Link from 'next/link'
import { useState, type FunctionComponent } from 'react'
import type { HeaderLocaleResult } from '../data/HeaderLocaleFragment'
import { usePageContext } from '../pages/[[...path]]'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import styles from './Header.module.sass'
import { Icon } from './Icon'
import { LanguageSwitcher } from './LanguageSwitcher'

export type HeaderProps = {
	header: HeaderLocaleResult
	translations: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
}

export const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
	const [open, setOpen] = useState(false)

	const page = usePageContext()
	const activeUrl = page.data.getLinkable?.url

	return (
		<HidingHeader>
			<div className={styles.wrapper}>
				<Container size="fullWidth">
					<div className={styles.in}>
						<div className={styles.topMobile}>
							<div className={styles.mobileLogo}>
								<Link href="/">
									<Icon name="logoMobile" />
								</Link>
							</div>

							<button className={styles.menuButton} onClick={() => setOpen((old) => !old)}>
								<Icon name="hamburger" />
							</button>
						</div>
						<div className={clsx(styles.mobileHeader, open && styles.isOpen)}>
							<Container size="wide">
								<div className={styles.topMobile}>
									<div className={styles.mobileLogo}>
										<Link href="/">
											<div className={styles.mobileLogoOpen}>
												<Icon name="logoMobile" />
											</div>
										</Link>
									</div>

									<button className={styles.menuButton} onClick={() => setOpen((old) => !old)}>
										<Icon name="close" />
									</button>
								</div>

								{props.header.specialLink && (
									<div className={styles.specialLink}>
										<Button
											type="link"
											{...contemberLinkToHrefTargetRel(props.header.specialLink)}
											variant="primary"
											size="small"
											onClick={() => setOpen((old) => !old)}
										>
											{props.header.specialLink.title ?? contemberLinkToHref(props.header.specialLink)}
										</Button>
									</div>
								)}
								<ul className={styles.mobileLinks}>
									{props.header.links?.items.map((item) => (
										<li key={item.id} className={styles.item}>
											{item.link && (
												<div className={styles.itemIn} onClick={() => setOpen((old) => !old)}>
													<Link
														className={clsx(
															styles.mobileLink,
															activeUrl === item.link.internalLink?.url && styles.is_active,
														)}
														{...contemberLinkToHrefTargetRel(item.link)}
													>
														{item.link.title ?? contemberLinkToHref(item.link)}
													</Link>
													{item.icon && (
														<div className={styles.itemIcon}>
															<ContemberImage image={item.icon} />
														</div>
													)}
												</div>
											)}
										</li>
									))}
								</ul>

								<div className={styles.languageSwitcher}>
									<LanguageSwitcher localeCode={props.header.locale?.code ?? ''} translations={props.translations} />
								</div>
							</Container>
						</div>
						<div className={styles.top}>
							{props.header.logo && (
								<div className={styles.desktopLogo}>
									<Link href="/">
										<ContemberImage image={props.header.logo} />
									</Link>
								</div>
							)}
							<div className={styles.specialLinkWrapper}>
								{props.header.specialLink && (
									<div className={styles.specialLink}>
										<Button
											type="link"
											{...contemberLinkToHrefTargetRel(props.header.specialLink)}
											variant="primary"
											size="small"
										>
											{props.header.specialLink.title ?? contemberLinkToHref(props.header.specialLink)}
										</Button>
									</div>
								)}
								<div className={styles.languageSwitcher}>
									<LanguageSwitcher localeCode={props.header.locale?.code ?? ''} translations={props.translations} />
								</div>
							</div>
						</div>
						<ul className={styles.links}>
							{props.header.links?.items.map((item) => (
								<li key={item.id} className={styles.item}>
									{item.link && (
										<div className={styles.itemIn}>
											<Link
												className={clsx(styles.link, activeUrl === item.link.internalLink?.url && styles.is_active)}
												{...contemberLinkToHrefTargetRel(item.link)}
											>
												{item.link.title ?? contemberLinkToHref(item.link)}
											</Link>
											{item.icon && (
												<div className={styles.itemIcon}>
													<ContemberImage image={item.icon} />
												</div>
											)}
										</div>
									)}
								</li>
							))}
						</ul>
					</div>
				</Container>
			</div>
		</HidingHeader>
	)
}
