import { RichTextRenderer } from '@contember/react-client'
import 'hiding-header/dist/style.css'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { FooterLocaleResult } from '../data/FooterLocaleFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import styles from './Footer.module.sass'
import { LanguageSwitcher } from './LanguageSwitcher'

export type FooterProps = {
	footer: FooterLocaleResult
	translations: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
}

export const Footer: FunctionComponent<FooterProps> = (props: FooterProps) => {
	return (
		<div className={styles.wrapper}>
			<Container size="fullWidth">
				<div className={styles.container}>
					<div className={styles.logos}>
						{props.footer.logo && <ContemberImage image={props.footer.logo} />}
						<div className={styles.otherLogos}>
							{props.footer.nationalMuseumLogo && <ContemberImage image={props.footer.nationalMuseumLogo} />}
							{props.footer.universityLogo && <ContemberImage image={props.footer.universityLogo} />}
						</div>
					</div>
					<div className={styles.columns}>
						<div className={styles.linksWrapper}>
							<div className={styles.column}>
								<div className={styles.columnTitle}>{props.footer.firstColumnTitle}</div>
								{props.footer.firstColumnLinks?.items.map(
									({ id, link }) =>
										link && (
											<Link key={id} className={styles.link} {...contemberLinkToHrefTargetRel(link)}>
												{link.title ?? contemberLinkToHref(link)}
											</Link>
										),
								)}
							</div>
							<div className={styles.column}>
								<div className={styles.columnTitle}>{props.footer.secondColumnTitle}</div>
								{props.footer.secondColumnLinks?.items.map(
									({ id, link }) =>
										link && (
											<Link key={id} className={styles.link} {...contemberLinkToHrefTargetRel(link)}>
												{link.title ?? contemberLinkToHref(link)}
											</Link>
										),
								)}
							</div>
						</div>
						<div className={styles.languageSwitcher}>
							<LanguageSwitcher
								localeCode={props.footer.locale?.code ?? ''}
								translations={props.translations}
								isPurple
							/>
						</div>
					</div>
				</div>
				<div className={styles.madeBy}>{props.footer.madeBy && <RichTextRenderer source={props.footer.madeBy} />}</div>
			</Container>
		</div>
	)
}
