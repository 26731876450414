import type { ReferenceRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Link from 'next/link'
import { useMemo, type FunctionComponent, type ReactNode } from 'react'
import type { ContentReferenceType } from '../../generated/contember/zeus'
import type { ContentBlockResult } from '../data/ContentBlockFragment'
import type { ContentResult } from '../data/ContentFragment'
import { usePageContext } from '../pages/[[...path]]'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { Cards } from './Cards'
import { Collapsible } from './Collapsible'
import { ContactForm } from './ContactForm'
import { Container } from './Container'
import styles from './ContentRenderer.module.sass'
import { Cover } from './Cover'
import { Embed } from './Embed'
import { FileOrLink } from './FileOrLink'
import { FileOrLinkBox } from './FileOrLinkBox'
import { Graduates } from './Graduates'
import { LeadParagraph } from './LeadParagraph'
import { Lecturers } from './Lecturers'
import { Linkables } from './Linkables'
import { NewsPostCarousel } from './NewsPostCarousel'
import { Partners } from './Partners'
import { ResponsiveImage } from './ResponsiveImage'
import { Sections } from './Sections'
import { ThreeImages } from './ThreeImages'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	containerDisableGutters?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget', 'link']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	image: function image({ reference }) {
		return (
			reference.image && (
				<Container size="wide">
					<ResponsiveImage
						src={reference.image.url}
						width={reference.image.width}
						height={reference.image.height}
						alt={reference.image.alt ?? ''}
					/>
				</Container>
			)
		)
	},
	linkables: function linkables({ reference }) {
		return (
			<Container>
				<Linkables title={reference.primaryText} items={reference.linkables} />
			</Container>
		)
	},
	link: function link({ reference }) {
		return (
			reference.link && (
				<Container>
					<Link {...contemberLinkToHrefTargetRel(reference.link)}>{reference.link.title}</Link>
				</Container>
			)
		)
	},
	lecturers: function lecturers({ reference }) {
		return (
			reference.lecturers && (
				<Container size="fullWidth">
					<Lecturers title={reference.primaryText} lecturers={reference.lecturers} link={reference.link} />
				</Container>
			)
		)
	},
	embed: function embed({ reference }) {
		return (
			reference.embed && (
				<Container>
					<Embed {...reference.embed} />
				</Container>
			)
		)
	},
	leadParagraph: function leadParagraph({ reference }) {
		return (
			reference.primaryText && (
				<Container>
					<LeadParagraph text={reference.primaryText} />
				</Container>
			)
		)
	},
	sections: function sections({ reference }) {
		return reference.sections && <Sections sections={reference.sections} />
	},
	collapsible: function collapsible({ reference }) {
		return (
			reference && (
				<Container>
					<Collapsible title={reference.primaryText} textList={reference.textList} link={reference.link} />
				</Container>
			)
		)
	},
	fileOrLink: function fileOrLink({ reference }) {
		return (
			reference.fileOrLink && (
				<Container size={reference.fullWidth ? 'fullWidth' : 'normal'}>
					{/* <Container> */}
					<FileOrLink file={reference.fileOrLink} />
				</Container>
			)
		)
	},
	cover: function cover({ reference }) {
		return (
			<Container size="fullWidth">
				<Cover title={reference.primaryText} link={reference.link} image={reference.image} />
			</Container>
		)
	},
	cards: function cards({ reference }) {
		return (
			reference.cardList && (
				<Container size="fullWidth">
					<Cards cards={reference.cardList} />
				</Container>
			)
		)
	},

	contactForm: function Block({ reference }) {
		const page = usePageContext()

		return (
			page.data.getContactFormSettingLocale && (
				<Container size="fullWidth">
					<ContactForm contactForm={page.data.getContactFormSettingLocale} title={reference.primaryText} />
				</Container>
			)
		)
	},
	newsPostCarousel: function newsPostCarousel({ reference }) {
		return (
			<NewsPostCarousel
				title={reference.primaryText}
				selectedNewsPosts={reference.newsPosts}
				link={reference.link}
				type={reference.newsPostsType ?? 'current'}
			/>
		)
	},
	partners: function partners({ reference }) {
		return (
			reference.imageList && (
				<Container size="fullWidth">
					<Partners title={reference.primaryText} partners={reference.imageList} />
				</Container>
			)
		)
	},
	threeImages: function threeImages({ reference }) {
		return (
			reference.imageList && (
				<Container size="fullWidth">
					<ThreeImages title={reference.primaryText} threeImages={reference.imageList} link={reference.link} />
				</Container>
			)
		)
	},
	graduates: function graduates({ reference }) {
		return (
			reference.graduateList && (
				<Graduates title={reference.primaryText} text={reference.secondaryText} graduates={reference.graduateList} />
			)
		)
	},
	fileOrLinkBox: function fileOrLinkBox({ reference }) {
		return (
			reference.fileOrLinkList && (
				<FileOrLinkBox
					title={reference.primaryText}
					fileOrLinkList={reference.fileOrLinkList}
					link={reference.link}
					isFullWidth={reference.fullWidth}
				/>
			)
		)
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return useMemo(
		() => (
			<div className={styles.wrapper}>
				<RichTextRenderer
					blocks={blocks}
					sourceField="json"
					renderElement={(element) => {
						const { type } = element.element

						if (type === 'table') {
							return (
								<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
									<Container>{element.fallback}</Container>
								</div>
							)
						}

						if (nestedTypes.includes(type)) {
							const reference = element.reference as ContentBlockResult['references'][number] // @TODO: remove cast
							if (element.referenceType === 'link' && reference.link) {
								return <Link {...contemberLinkToHrefTargetRel(reference.link)}>{element.children}</Link>
							}
							return element.fallback
						}

						if (standaloneTypes.includes(type)) {
							return (
								<div
									className={clsx(
										styles.section,
										element.referenceType && styles[`is_reference_${element.referenceType}`],
									)}
								>
									{type !== 'reference' || !element.referenceType || element.referenceType in referenceRenderers ? (
										element.fallback
									) : (
										<Container disableGutters={containerDisableGutters}>
											<div className={styles.notImplemented}>
												<div className={styles.notImplemented_name}>{element.referenceType}</div>
												is not yet implemented
											</div>
										</Container>
									)}
								</div>
							)
						}
						return (
							<div className={clsx(styles.section, styles.is_wysiwyg)}>
								<Container disableGutters={containerDisableGutters}>
									<Wysiwyg>{element.fallback}</Wysiwyg>
								</Container>
							</div>
						)
					}}
					referenceRenderers={referenceRenderers}
				/>
			</div>
		),
		[blocks, containerDisableGutters],
	)
}
