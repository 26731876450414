import type { FunctionComponent } from 'react'
import type { CardListResult } from '../data/CardListFragment'
import styles from './Cards.module.sass'
import { ContemberImage } from './ContemberImage'

export type CardsProps = {
	cards: CardListResult
}

export const Cards: FunctionComponent<CardsProps> = ({ cards }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.cards}>
				{cards.items.map((card) => (
					<div key={card.id} className={styles.card}>
						<div className={styles.title}>{card.title}</div>
						{card.image && (
							<div className={styles.column}>
								<ContemberImage image={card.image} />
							</div>
						)}
						<div className={styles.text}>{card.text}</div>
					</div>
				))}
			</div>
		</div>
	)
}
