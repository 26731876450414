import clsx from 'clsx'
import Link from 'next/link'
import type { NewsPostTileLocaleResult } from '../data/NewsPostTileLocaleFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { ContemberImage } from './ContemberImage'
import styles from './NewsPostTile.module.sass'

export type NewsPostTileProps = {
	newsPost: { localesByLocale?: NewsPostTileLocaleResult }
	fullWidth?: boolean
}

export function NewsPostTile(props: NewsPostTileProps) {
	return (
		<div className={clsx(styles.newsPost, props.fullWidth && styles.fullWidth)}>
			{props.newsPost.localesByLocale?.link ? (
				<Link {...contemberLinkToHrefTargetRel(props.newsPost.localesByLocale?.link)} className={styles.link}>
					<NewsPostTileIn {...props} />
				</Link>
			) : (
				<NewsPostTileIn {...props} />
			)}
		</div>
	)
}

const NewsPostTileIn = (props: NewsPostTileProps) => {
	return (
		<>
			{props.newsPost.localesByLocale?.root?.image && (
				<div className={styles.imageWrapper}>
					<div className={styles.image}>
						<ContemberImage image={props.newsPost.localesByLocale?.root?.image} layout="fill" objectFit="cover" />
					</div>
				</div>
			)}
			<div className={styles.date}>
				{new Intl.DateTimeFormat('cs', {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
				}).format(new Date(props.newsPost.localesByLocale?.root?.publishedAt ?? ''))}
			</div>
			<h3 className={styles.title}>{props.newsPost.localesByLocale?.title}</h3>
		</>
	)
}
