import type { LecturerResult } from '../data/LecturerFragment'
import type { LecturersPageLocaleResult } from '../data/LecturersPageLocaleFragment'
import { Container } from './Container'
import styles from './LecturersPage.module.sass'
import { LecturerTile } from './LecturerTile'

export type LecturersPageProps = {
	lecturersPage: LecturersPageLocaleResult
	lecturers: LecturerResult[]
}

export function LecturersPage(props: LecturersPageProps) {
	return (
		<div className={styles.wrapper}>
			<Container size="fullWidth">
				<h1 className={styles.pageTitle}>{props.lecturersPage.title}</h1>
				<div className={styles.lecturers}>
					{props.lecturers.map((lecturer) => (
						<LecturerTile key={lecturer.id} lecturer={lecturer} />
					))}
				</div>
			</Container>
		</div>
	)
}
