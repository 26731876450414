import clsx from 'clsx'
import { useMemo } from 'react'
import type { NewsPostsType } from '../../generated/contember/zeus'
import type { LinkResult } from '../data/LinkFragment'
import type { NewsPostTileLocaleResult } from '../data/NewsPostTileLocaleFragment'
import { usePageContext } from '../pages/[[...path]]'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import { Icon } from './Icon'
import styles from './NewsPostCarousel.module.sass'
import { NewsPostTile } from './NewsPostTile'
import { useCarousel } from './useCarousel'

export type NewsPostCarouselProps = {
	title?: string
	selectedNewsPosts: { localesByLocale?: NewsPostTileLocaleResult }[]
	link?: LinkResult
	type: NewsPostsType
}

export function NewsPostCarousel(props: NewsPostCarouselProps) {
	const page = usePageContext()

	const { cardsElement, onSlideChange, showPreviousButton, showNextButton, scroll } = useCarousel()

	const posts = useMemo(() => {
		if (props.type === 'current') {
			return page.newsPostsCarouselSix?.listNewsPost ?? []
		}
		return props.selectedNewsPosts
	}, [page.newsPostsCarouselSix?.listNewsPost, props.selectedNewsPosts, props.type])

	return (
		<div className={styles.wrapper}>
			<Container size="fullWidth" disableGutters>
				<Container size="fullWidth">
					<div className={styles.carouselHeader}>
						<h2>{props.title}</h2>
						<div className={styles.navigation}>
							<div className={styles.arrowWrapperPrevious}>
								<button
									type="button"
									className={clsx(styles.arrow, showPreviousButton && styles.isVisible)}
									onClick={() => scroll(-1)}
									aria-label="previous"
								>
									<Icon name="arrowLeft" />
								</button>
							</div>
							<div className={styles.arrowWrapperNext}>
								<button
									type="button"
									className={clsx(styles.arrow, showNextButton && styles.isVisible)}
									onClick={() => scroll(1)}
									aria-label="next"
								>
									<Icon name="arrowRight" />
								</button>
							</div>
						</div>
					</div>
				</Container>
				<div className={styles.carouselWrapper}>
					<Container size="fullWidth" disableGutters>
						<div className={styles.carousel} onScroll={() => onSlideChange()} ref={cardsElement}>
							{posts.map((newsPost) => (
								<div className={styles.itemWrapper} key={newsPost.localesByLocale?.id}>
									<NewsPostTile newsPost={newsPost} key={newsPost.localesByLocale?.id} fullWidth />
								</div>
							))}
						</div>
					</Container>
				</div>
				{props.link && (
					<Container size="fullWidth">
						<div className={styles.linkWrapper}>
							<Button {...contemberLinkToHrefTargetRel(props.link)} type="link" variant="primary" size="medium">
								{props.link.title ?? contemberLinkToHref(props.link)}
							</Button>
						</div>
					</Container>
				)}
			</Container>
		</div>
	)
}
