/* eslint-disable prettier/prettier */
import { createContext, useContext } from 'react'
import { Footer } from '../components/Footer'
import { GenericPage } from '../components/GenericPage'
import { Header } from '../components/Header'
import { LecturersPage } from '../components/LecturersPage'
import { NewsPostPage } from '../components/NewsPostPage'
import { Seo } from '../components/Seo'
import { ContactFormSettingLocaleFragment } from '../data/ContactFormSetting'
import { FooterLocaleFragment } from '../data/FooterLocaleFragment'
import { GeneralFragment } from '../data/GeneralFragment'
import { GenericPageLocaleFragment } from '../data/GenericPageLocaleFragment'
import { HeaderLocaleFragment } from '../data/HeaderLocaleFragment'
import { LatestNewsPostFragment } from '../data/LatestNewsPostFragment'
import { LecturerFragment } from '../data/LecturerFragment'
import { LecturersPageLocaleFragment } from '../data/LecturersPageLocaleFragment'
import { LinkFragment } from '../data/LinkFragment'
import { NewsPostPageLocaleFragment } from '../data/NewsPostPageLocaleFragment'
import { NewsPostTileLocaleFragment } from '../data/NewsPostTileLocaleFragment'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { filterNonEmpty } from '../utilities/filterNonEmpty'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import type { InferDataLoaderProps } from '../utilities/handlers'
import { handleGetStaticPaths, handleGetStaticProps } from '../utilities/handlers'

export type PageProps = InferDataLoaderProps<typeof getStaticProps>

export default function (props: PageProps) {
	const header = props.data.getHeaderLocale
	const { genericPage, newsPostPage, lecturersPage } = props.data.getLinkable ?? {}
	const footer = props.data.getFooterLocale

	const pageLocales = [genericPage, newsPostPage, lecturersPage].filter(filterNonEmpty)[0]
	return (
		<PageContext.Provider value={props}>
			<Seo {...props.seo} />
			{header && <Header header={header} translations={pageLocales.root?.locales ?? []} />}
			{genericPage && <GenericPage genericPage={genericPage} />}
			{newsPostPage && props.newsPosts && <NewsPostPage newsPostPage={newsPostPage} newsPosts={props.newsPosts} />}
			{lecturersPage && props.lecturers?.listLecturer && (
				<LecturersPage lecturersPage={lecturersPage} lecturers={props.lecturers.listLecturer} />
			)}
			{footer && <Footer footer={footer} translations={pageLocales.root?.locales ?? []} />}
		</PageContext.Provider>
	)
}

export const getStaticPaths = handleGetStaticPaths(async (context) => {
	const { listLinkable } = await contember.query({
		listLinkable: [
			{
				filter: {
					redirect: { id: { isNull: true } },
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')

			let locale: string | undefined

			if (context.locales?.includes(path[0])) {
				locale = path.shift()
			}

			return {
				locale,
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
})

export const getStaticProps = handleGetStaticProps(async (context) => {
	const url = getLinkableUrlFromContext(context)
	const { locale } = context

	if (!locale) {
		throw new Error('Locale not defined.')
	}

	const data = await contember.query({
		getGeneral: [
			{
				by: {
					unique: 'One',
				},
			},
			GeneralFragment(locale),
		],
		getHeaderLocale: [
			{
				by: {
					root: { unique: 'One' },
					locale: { code: locale },
				},
			},
			HeaderLocaleFragment(),
		],
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				genericPage: [{}, GenericPageLocaleFragment(locale)],
				newsPostPage: [{}, NewsPostPageLocaleFragment()],
				lecturersPage: [{}, LecturersPageLocaleFragment()],

				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
		getContactFormSettingLocale: [{
			by: {
				root: { unique: 'One' },
				locale: { code: locale },
			},
		}, ContactFormSettingLocaleFragment()],
		getFooterLocale: [
			{
				by: {
					root: { unique: 'One' },
					locale: { code: locale },
				},
			},
			FooterLocaleFragment(),
		],
	})

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
		}
	}

	const canonicalUrl = (() => {
		const url = data.getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.NEXT_PUBLIC_WEB_URL ?? '') + url
	})()

	const newsPosts = data.getLinkable?.newsPostPage?.id
		? await contember
			.query(LatestNewsPostFragment(locale, 1))
			.then((data) => data.paginateNewsPost.edges.map(({ node }) => node))
		: null

	const newsPostsCarouselSix = data.getLinkable?.genericPage
		? await contember.query({
			listNewsPost: [
				{ orderBy: [{ publishedAt: 'desc' }], limit: 6 },
				{ localesByLocale: [{ by: { locale: { code: locale } } }, NewsPostTileLocaleFragment()] },
			],
		})
		: null

	const lecturers = data.getLinkable?.lecturersPage?.id
		? await contember.query({
			listLecturer: [{ orderBy: [{ name: 'asc' }] }, LecturerFragment(locale)],
		})
		: null

	const page = data.getLinkable?.genericPage ?? data.getLinkable?.newsPostPage ?? data.getLinkable?.lecturersPage

	if (!page) {
		return {
			notFound: true,
		}
	}

	return {
		props: {
			data,
			newsPosts,
			newsPostsCarouselSix,
			lecturers,
			general: data.getGeneral,
			page,
			locale,
			seo: {
				canonicalUrl,
				seo: {
					...(data.getGeneral?.localesByLocale?.seo ?? {}),
					...Object.fromEntries(Object.entries(page.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
		},
		revalidate: 60,
	}
})

export const PageContext = createContext<null | PageProps>(null)

export function usePageContext() {
	const context = useContext(PageContext)
	if (!context) {
		throw new Error('Missing PageContext')
	}
	return context
}
