import { useEffect, useRef, useState } from 'react'

export function useCarousel() {
	const cardsElement = useRef<HTMLDivElement>(null)

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null) {
			return
		}

		cardsElement.current.scrollBy({
			left: direction,
			behavior: 'smooth',
		})
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}

		setShowPreviousButton(true)
		setShowNextButton(true)

		if (cardsElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])

	return {
		cardsElement,
		onSlideChange,
		showPreviousButton,
		showNextButton,
		scroll,
	}
}
