import type { NewsPostPageLocaleResult } from '../data/NewsPostPageLocaleFragment'
import type { NewsPostTileLocaleResult } from '../data/NewsPostTileLocaleFragment'
import { usePageContext } from '../pages/[[...path]]'
import { api } from '../utilities/api'
import { Button } from './Button'
import { Container } from './Container'
import styles from './NewsPostPage.module.sass'
import { NewsPostTile } from './NewsPostTile'

export type NewsPostPageProps = {
	newsPostPage: NewsPostPageLocaleResult
	newsPosts: { localesByLocale?: NewsPostTileLocaleResult }[]
}

export function NewsPostPage(props: NewsPostPageProps) {
	const page = usePageContext()

	const infinite = api.loader.newsPosts.useInfiniteQuery(
		{
			locale: page.locale,
		},
		{
			getNextPageParam: (lastPage) => lastPage.nextCursor,
			initialCursor: 1,
			refetchOnMount: false,
			initialData: {
				pageParams: [1],
				pages: [
					{
						status: 'ok',
						items: props.newsPosts,
						cursor: 1,
						nextCursor: 2,
					},
				],
			},
		},
	)

	const visibleNewsPosts = infinite.data?.pages.flatMap((page) => page.items) ?? props.newsPosts

	return (
		<div className={styles.wrapper}>
			<Container size="fullWidth">
				<h1 className={styles.pageTitle}>{props.newsPostPage.title}</h1>
				<div className={styles.newsPosts}>
					{visibleNewsPosts.map(
						(newsPost) =>
							newsPost.localesByLocale && <NewsPostTile newsPost={newsPost} key={newsPost.localesByLocale.id} />,
					)}
				</div>
				{infinite.hasNextPage && (
					<div className={styles.button}>
						<Button type="button" variant="primary" size="medium" onClick={() => infinite.fetchNextPage()}>
							Zobrazit starší
						</Button>
					</div>
				)}
			</Container>
		</div>
	)
}
