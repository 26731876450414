import clsx from 'clsx'
import { useState } from 'react'
import type { SectionListResult } from '../data/SectionListFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Icon } from './Icon'
import styles from './Sections.module.sass'

export type SectionsProps = {
	sections: SectionListResult
}

export function Sections(props: SectionsProps) {
	return (
		<div className={styles.sections}>
			<Container>
				<h2 className={styles.name}>{props.sections.name}</h2>
			</Container>

			<div className={styles.sectionsNav}>
				{props.sections.items.map((section) => (
					<a className={styles.navTitle} key={section.id} href={`#${section.sectionTitle}`}>
						{section.navTitle}
					</a>
				))}
			</div>
			<Container size="fullWidth">
				{props.sections.items.map((section) => (
					<SectionItem key={section.id} section={section} sections={props.sections} />
				))}
			</Container>
		</div>
	)
}
export function SectionItem(props: {
	section: NonNullable<SectionsProps['sections']['items']>[number]
	sections: SectionsProps['sections']
}) {
	const [open, setOpen] = useState(false)

	return (
		<div id={props.section.sectionTitle}>
			<button className={styles.menuButton} onClick={() => setOpen((old) => !old)}>
				<div className={styles.menuButtonLabel}>{props.section.navTitle}</div>
				<div className={styles.icon}>
					<Icon name="arrow" />
				</div>
			</button>

			<h3 className={styles.sectionTitleDesktop}>{props.section.sectionTitle}</h3>

			<div className={clsx(styles.mobileSectionsNav, open && styles.isOpen)}>
				{props.sections.items.map((section) => (
					<a
						className={styles.navTitle}
						key={section.id}
						href={`#${section.sectionTitle}`}
						onClick={() => setOpen((old) => !old)}
					>
						{section.navTitle}
					</a>
				))}
			</div>
			{props.section.content && (
				<div className={styles.content}>
					<ContentRenderer content={props.section.content} />
				</div>
			)}
		</div>
	)
}
