import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { FileOrLinkResult } from '../data/FileOrLinkFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import styles from './FileOrLink.module.sass'
import { Icon } from './Icon'

export type FileOrLinkProps = {
	file: FileOrLinkResult
}

export const FileOrLink: FunctionComponent<FileOrLinkProps> = ({ file }) => {
	const icon =
		file.icon === 'globe' ? (
			<Icon name="globe" />
		) : file.icon === 'file' ? (
			<Icon name="file" />
		) : file.icon === 'play' ? (
			<Icon name="play" />
		) : null

	const rootDomain = file.link?.externalLink
		?.replace('http://', '')
		.replace('https://', '')
		.replace('www.', '')
		.split(/[/?#]/)[0]

	console.log(rootDomain)
	return (
		<>
			{file && file.type === 'link' && file.link && (
				<Link className={styles.wrapper} {...contemberLinkToHrefTargetRel(file.link)}>
					<div className={styles.iconTitleWrapper}>
						<div className={styles.icon}>{icon}</div>
						<div className={styles.titleWrapper}>
							<div className={styles.title}>{file.title}</div>
							<div className={styles.fileInfo}>
								<div className={styles.domain}>{rootDomain}</div>
							</div>
						</div>
					</div>
					<div className={styles.icon}>
						<Icon name="arrowLink" />
					</div>
				</Link>
			)}
			{file && file.type === 'file' && file.file?.url && (
				<a className={styles.wrapper} href={file.file.url} download={file.file.name}>
					<div className={styles.iconTitleWrapper}>
						<div className={styles.icon}>{icon}</div>
						<div className={styles.titleWrapper}>
							<div className={styles.title}>{file.title}</div>
							<div className={styles.fileInfo}>
								<div className={styles.fileSize}>{humanFileSize(file.file.size)}</div>
								<div className={styles.fileType}>.{file.file.name?.split('.').pop()}</div>
							</div>
						</div>
					</div>
					<div className={styles.icon}>
						<Icon name="download" />
					</div>
				</a>
			)}
		</>
	)
}

function humanFileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B'
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	let u = -1
	const r = 10 ** dp

	do {
		bytes /= thresh
		++u
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

	return bytes.toFixed(dp) + ' ' + units[u]
}
