import type { FunctionComponent } from 'react'
import type { FileOrLinkListResult } from '../data/FileOrLinkListFragment'
import type { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import { FileOrLink } from './FileOrLink'
import styles from './FileOrLinkBox.module.sass'

export type FileOrLinkBoxProps = {
	title?: string
	fileOrLinkList: FileOrLinkListResult
	link?: LinkResult
	isFullWidth?: boolean
}

export const FileOrLinkBox: FunctionComponent<FileOrLinkBoxProps> = ({ title, fileOrLinkList, link, isFullWidth }) => {
	return (
		<Container size={isFullWidth ? 'fullWidth' : 'normal'}>
			<div className={styles.wrapper}>
				<h2>{title}</h2>
				{fileOrLinkList.items.map(
					(item) =>
						item.fileOrLink && (
							<div className={styles.fileOrLink} key={item.id}>
								<FileOrLink file={item.fileOrLink} />
							</div>
						),
				)}
				{link && (
					<div className={styles.link}>
						<Button type="link" {...contemberLinkToHrefTargetRel(link)} variant="primary" size="small">
							{link.title ?? contemberLinkToHref(link)}
						</Button>
					</div>
				)}
			</div>
		</Container>
	)
}
