import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { Collapsible as ExternalCollapsible } from 'collapsible-react-component'
import 'collapsible-react-component/dist/index.css'
import { useState, type FunctionComponent } from 'react'
import type { LinkResult } from '../data/LinkFragment'
import type { TextListResult } from '../data/TextListFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import styles from './Collapsible.module.sass'
import { Icon } from './Icon'

export type CollapsibleProps = {
	title?: string
	textList?: TextListResult
	link?: LinkResult
}

export const Collapsible: FunctionComponent<CollapsibleProps> = ({ title, textList, link }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapperIn}>
				<h2>{title}</h2>
				{textList?.items.map((item) => (
					<CollapsibleItem key={item.id} {...item} />
				))}
				{link && (
					<div className={styles.link}>
						<Button type="link" {...contemberLinkToHrefTargetRel(link)} variant="primary">
							{link.title ?? contemberLinkToHref(link)}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}
export const CollapsibleItem: FunctionComponent<NonNullable<CollapsibleProps['textList']>['items'][number]> = ({
	id,
	text,
	title,
}) => {
	const [open, setOpen] = useState(false)

	return (
		<>
			<button
				type="button"
				onClick={() => {
					setOpen(!open)
				}}
				className={clsx(styles.collapsibleTitleWrapper, open && styles.isOpen)}
			>
				<h4 className={styles.collapsibleTitle}>{title}</h4>
				{open ? (
					<div className={styles.minus}>
						<Icon name="arrow" />
					</div>
				) : (
					<div className={styles.plus}>
						<Icon name="arrow" />
					</div>
				)}
			</button>
			<ExternalCollapsible key={id} open={open}>
				{text && (
					<div className={styles.collapsibleText}>
						<div className={styles.collapsibleTextIn}>
							<RichTextRenderer source={text} />
						</div>
					</div>
				)}
			</ExternalCollapsible>
		</>
	)
}
