import type { FunctionComponent } from 'react'
import type { ImageListResult } from '../data/ImageListFragment'
import type { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import styles from './ThreeImages.module.sass'

export type ThreeImagesProps = {
	title?: string
	threeImages: ImageListResult
	link?: LinkResult
}

export const ThreeImages: FunctionComponent<ThreeImagesProps> = ({ title, threeImages, link }) => {
	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>{title}</h3>
			<div className={styles.images}>
				{threeImages.items.map((image) => (
					<div key={image.id} className={styles.imageWrapper}>
						{image.image && (
							<div className={styles.image}>
								<ContemberImage image={image.image} />
							</div>
						)}
						<div className={styles.imageText}>{image.text}</div>
					</div>
				))}
			</div>
			{link && (
				<div className={styles.link}>
					<Button type="link" {...contemberLinkToHrefTargetRel(link)} variant="primary" size="medium">
						{link.title ?? contemberLinkToHref(link)}
					</Button>
				</div>
			)}
		</div>
	)
}
