import type { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import { ContentRenderer } from './ContentRenderer'
import styles from './GenericPage.module.sass'

export type GenericPageProps = {
	genericPage: GenericPageLocaleResult
}

export function GenericPage(props: GenericPageProps) {
	return (
		<div className={styles.wrapper}>
			{props.genericPage.content && <ContentRenderer content={props.genericPage.content} />}
		</div>
	)
}
