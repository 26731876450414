import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import styles from './LeadParagraph.module.sass'

export type LeadParagraphProps = {
	text: string
}

export const LeadParagraph: FunctionComponent<LeadParagraphProps> = (props: LeadParagraphProps) => {
	return (
		<div className={styles.wrapper}>
			<RichTextRenderer source={props.text} />
		</div>
	)
}
