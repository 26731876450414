import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { LecturerResult } from '../data/LecturerFragment'
import { ContemberImage } from './ContemberImage'
import styles from './LecturerTile.module.sass'

export type LecturerTileProps = {
	lecturer: LecturerResult
}

export const LecturerTile: FunctionComponent<LecturerTileProps> = ({ lecturer }) => {
	return (
		<div className={styles.lecturer}>
			{lecturer.image && (
				<div className={styles.image}>
					<ContemberImage image={lecturer.image} layout="fill" objectFit="cover" />
				</div>
			)}
			<div className={styles.content}>
				<div className={styles.name}>{lecturer.name}</div>
				{lecturer.localesByLocale?.text && (
					<div className={styles.text}>
						<RichTextRenderer source={lecturer.localesByLocale.text} />
					</div>
				)}
			</div>
		</div>
	)
}
