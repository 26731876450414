import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'
import type { ContactFormSettingLocaleResult } from '../data/ContactFormSetting'
import { api } from '../utilities/api'
import { Button } from './Button'
import styles from './ContactForm.module.sass'
import { ContemberImage } from './ContemberImage'

export type ContactFormProps = {
	title?: string
	contactForm: ContactFormSettingLocaleResult
}

export function ContactForm(props: ContactFormProps) {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [text, setText] = useState('')

	const mutation = api.contact.send.useMutation({
		onSuccess: () => {
			toast('Formulář byl úspěšně odeslán.', {
				type: 'success',
			})
			setName('')
			setEmail('')
			setText('')
		},
		onError: () => {
			toast('Něco se při odesílání pokazilo. Zkuste to znovu prosím později.', {
				type: 'error',
			})
		},
	})
	const isLoading = mutation.isLoading
	useMirrorLoading(isLoading)

	return (
		<div className={styles.wrapper}>
			{props.title && (
				<div className={styles.titleWrapper}>
					<h2 className={styles.title}>
						<RichTextRenderer source={props.title} />
					</h2>
				</div>
			)}
			<div className={styles.formWrapper}>
				<form
					className={styles.form}
					onSubmit={(event) => {
						event.preventDefault()
						mutation.mutate({
							name,
							email,
							text,
						})
					}}
				>
					<label className={styles.item}>
						<div className={styles.inputLabelWrapper}>
							<span className={styles.inputLabel}>{props.contactForm.nameLabel} </span>
							<span className={styles.inputDescription}>{props.contactForm.nameDescription}</span>
						</div>
						<input
							className={styles.input}
							type="text"
							autoComplete="name"
							value={name}
							onChange={(event) => {
								setName(event.target.value)
							}}
						/>
					</label>
					<label className={styles.item}>
						<div className={styles.inputLabelWrapper}>
							<span className={styles.inputLabel}>{props.contactForm.emailLabel} </span>
							<span className={styles.inputDescription}>{props.contactForm.emailDescription}</span>
						</div>
						<input
							className={styles.input}
							type="email"
							autoComplete="email"
							value={email}
							onChange={(event) => {
								setEmail(event.target.value)
							}}
						/>
					</label>
					<label className={styles.item}>
						<div className={styles.inputLabelWrapper}>
							<span className={styles.inputLabel}>{props.contactForm.messageLabel} </span>
							<span className={styles.inputDescription}>{props.contactForm.messageDescription}</span>
						</div>
						<textarea
							className={clsx(styles.input, styles.textArea)}
							value={text}
							onChange={(event) => {
								setText(event.target.value)
							}}
						/>
					</label>
					<div className={styles.emailToBox}>
						{props.contactForm.emailToImage && (
							<div className={styles.image}>
								<ContemberImage image={props.contactForm.emailToImage} layout="fill" objectFit="cover" />
							</div>
						)}
						<div className={styles.emailToNameWrapper}>
							<div className={styles.emailToName}>{props.contactForm.emailToName}</div>
							<div className={styles.emailToDescription}>{props.contactForm.emailToDescription}</div>
						</div>
					</div>
					<div className={styles.submit}>
						<Button type="submit" size="medium" variant="primary" disabled={isLoading}>
							{props.contactForm.submitLabel}
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}
